<template>
  <base-view
    title="Pólizas Servicios Administrativos"
    description="Visualiza la información de las pólizas importadas por el área de Servicios Administrativos"
    background="bg-gradient-light-to-black"
    icon="repeat"
    header-type="overlap"
  >
    <b-card no-body>
      <b-card-header>
        <div class="row align-items-center">
          <x-periodo-fiscal-tag variant="primary" />
          <div class="col">
            <check-authorization :requiresAuthorizations="['acceso periodos fiscales']">
              <router-link :to="{ name: 'PeriodosFiscales' }">
                <b-button size="sm">
                  Cambiar periodo fiscal activo
                </b-button>
              </router-link>
            </check-authorization>
          </div>
          <!-- Search -->
          <b-input-group size="sm" class="w-100 mx-2" style="max-width: 400px">
            <b-input-group-prepend>
              <b-input-group-text class="py-0">
                <b-icon-search></b-icon-search>
              </b-input-group-text>
            </b-input-group-prepend>
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Buscar por folio, concepto, fecha, elabora o momento"
            ></b-form-input>
          </b-input-group>
          <b-button size="sm" variant="success" :disabled="loading || selectedIds.length <= 0" @click="onExport">
            Exportar
          </b-button>
        </div>
      </b-card-header>

      <confirm-cancelacion-poliza-dialog v-show="showModal" @on-submitted="submitted"></confirm-cancelacion-poliza-dialog>

      <b-overlay :show="Boolean(loading)">
        <template #overlay>
          <loading></loading>
        </template>
        <b-container>
          <b-row>
            <b-col lg="6">
              <b-pagination size="sm" align="fill"
                            v-model="currentPage"
                            :total-rows="totalRows"
                            :per-page="perPage"
                            aria-controls="polizas-table"
              >
              </b-pagination>
            </b-col>
            <b-col lg="2">
              <b-form-group
                label="Mes"
                label-for="mes-select"
                label-cols-sm="2"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
                v-slot="{ ariaDescribedby }"
              >
                <b-input-group size="sm">
                  <b-form-select
                    id="mes-select"
                    v-model="mes"
                    :options="meses"
                    :aria-describedby="ariaDescribedby"
                    class="w-75"
                  >
                  </b-form-select>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col lg="2">
              <b-form-group
                label="Usuario"
                label-for="usuario-select"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
                v-slot="{ ariaDescribedby }"
              >
                <b-input-group size="sm">
                  <b-form-select
                    id="usuario-select"
                    v-model="usuario"
                    :options="usuarios"
                    :aria-describedby="ariaDescribedby"
                    class="w-75"
                  >
                  </b-form-select>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col lg="2">
              <b-form-group
                label="Captura"
                label-for="captura-select"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
                v-slot="{ ariaDescribedby }"
              >
                <b-input-group size="sm">
                  <b-form-select
                    id="captura-select"
                    v-model="fecha"
                    :options="fechas"
                    :aria-describedby="ariaDescribedby"
                    class="w-75"
                  >
                  </b-form-select>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-table
            id="polizas-table"
            :fields="fields"
            :items="polizas"
            striped
            bordered
            responsive
            hover
            stacked="lg"
            show-empty
            empty-filtered-text="No se encontraron pólizas con los parámetros de búsqueda escritos"
            empty-text="No se encontraron pólizas con los parámetros de usuario y mes seleccionados"
            :per-page="perPage"
            :current-page="currentPage"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template #cell(concepto)="data">
              <div v-if="includeFilter(data.item.concepto, filter)" :class="data.item.status=='Activa'?'text-sm text-center text-primary w-100':'text-sm text-center text-danger w-100'">
                {{ leftContent(data.item.concepto, filter) }}<b style="background-color: yellow;">{{ filterContent(data.item.concepto, filter) }}</b>{{ rightContent(data.item.concepto, filter) }}
              </div>
              <div v-else :class="data.item.status=='Activa'?'text-sm text-center text-primary w-100':'text-sm text-center text-danger w-100'">
                {{ data.item.concepto }}
              </div>
            </template>
            <template #cell(folio)="data">
              <div v-if="includeFilter(data.item.folio, filter)" :class="data.item.status=='Activa'?'text-sm text-center text-primary w-100':'text-sm text-center text-danger w-100'">
                {{ leftContent(data.item.folio, filter) }}<b style="background-color: yellow;">{{ filterContent(data.item.folio, filter) }}</b>{{ rightContent(data.item.folio, filter) }}
              </div>
              <div v-else :class="data.item.status=='Activa'?'text-sm text-center text-primary w-100':'text-sm text-center text-danger w-100'">
                {{ data.item.folio }}
              </div>
            </template>
            <template #cell(fecha)="data">
              <div v-if="includeFilter(data.item.fecha, filter)" :class="data.item.status=='Activa'?'text-sm text-center text-primary w-100':'text-sm text-center text-danger w-100'">
                {{ leftContent(data.item.fecha, filter) }}<b style="background-color: yellow;">{{ filterContent(data.item.fecha, filter) }}</b>{{ rightContent(data.item.fecha, filter) }}
              </div>
              <div v-else :class="data.item.status=='Activa'?'text-sm text-center text-primary w-100':'text-sm text-center text-danger w-100'">
                {{ data.item.fecha }}
              </div>
            </template>
            <template #cell(momento)="data">
              <div class="text-sm text-center w-100">
                {{ data.item.momento }}
              </div>
            </template>
            <template #cell(elabora)="data">
              <div class="text-sm text-center w-100">
                {{ data.item.elabora }}
              </div>
            </template>
            <template #cell(usuario)="data">
              <div class="text-sm text-center w-100">
                {{ data.item.usuario }}
              </div>
            </template>
            <template #cell(captura)="data">
              <div class="text-sm text-center w-100">
                {{ data.item.captura }}
              </div>
            </template>
            <template #cell(mes)="data">
              <div class="text-sm text-primary text-center w-100">
                <b-badge :variant="data.item.status=='Activa'?'primary':'danger'" class="ml-2">
                  {{ getMonthById(data.item.mes).value }}
                </b-badge>
              </div>
            </template>
            <template #cell(status)="data">
              <div :class="data.item.status=='Activa'?'text-sm text-center text-primary w-100':'text-sm text-center text-danger w-100'">
                {{ data.item.status }}
              </div>
            </template>
            <template #cell(total_cargos)="data">
              <div :class="data.item.status=='Activa'?'text-sm text-center text-primary w-100':'text-sm text-center text-danger w-100'">
                {{ $formatNumber(data.item.total_cargos) }}
              </div>
            </template>
            <template #cell(total_abonos)="data">
              <div :class="data.item.status=='Activa'?'text-sm text-center text-primary w-100':'text-sm text-center text-danger w-100'">
                {{ $formatNumber(data.item.total_abonos) }}
              </div>
            </template>
            <template #row-details="data">
              <polizas-s-a-movimientos :poliza-id="data.item.id"></polizas-s-a-movimientos>
            </template>
            <template #cell(acciones)="row">
              <div class="d-flex justify-content-center align-items-center" style="font-size: 1rem">
                <b-button
                  class="btn-sm btn-outline-primary mr-3 mb-2 mb-sm-0"
                  size="sm"
                  variant="white"
                  @click="
                    row.toggleDetails()
                    onShowMovimientos(row.item.id)
                  "
                >
                  Movimientos
                </b-button>

                <check-authorization :requiresAuthorizations="['descargar poliza']">
                  <b-button
                    class="btn-sm btn-outline-secondary mr-3 mb-2 mb-sm-0"
                    size="sm"
                    variant="white"
                    @click="download(row.item.id, row.item.folio)"
                  >
                    Descargar
                  </b-button>
                </check-authorization>

                <check-authorization :requiresAuthorizations="['actualizar poliza']" hide>
                  <b-button
                    class="btn-sm btn-outline-danger mr-3 mb-2 mb-sm-0"
                    size="sm"
                    variant="white"
                    @click="cancelar(row.item.id, row.item.folio)"
                  >
                    Cancelar
                  </b-button>
                </check-authorization>
              </div>
            </template>
          </b-table>
        </b-container>
      </b-overlay>
    </b-card>
  </base-view>
</template>

<script>
import { mapGetters } from 'vuex'
import PolizasSAMovimientos from '@/components/Polizas/SA/PolizasSAMovimientos'
import ConfirmCancelacionPolizaDialog from '@/components/Polizas/ConfirmCancelacionPolizaDialog'

export default {
  name: 'PolizasSAListView',

  components: {
    PolizasSAMovimientos,
    ConfirmCancelacionPolizaDialog
  },

  data () {
    return {
      fields: ['folio', 'fecha', 'mes', 'concepto', 'momento', 'elabora', 'usuario', 'captura', 'status', 'total_cargos', 'total_abonos', 'acciones'],

      loading: false,

      selectedPoliza: null,

      selectedIds: null,

      polizas: null,

      meses: null,

      mes: null,

      showModal: false,

      usuarios: null,

      usuario: null,

      fechas: null,

      fecha: null,

      perPage: 10,

      currentPage: 1,

      totalRows: 1,

      filter: null,

      filterOn: ['folio', 'fecha', 'concepto'],

      id: null,

      folio: null
    }
  },

  computed: {
    ...mapGetters('UIModule', ['getMonthById'])
  },

  watch: {
    mes: {
      immediate: true,
      async handler (value) {
        await this.init()
      }
    },

    usuario: {
      immediate: true,
      async handler (value) {
        await this.init()
      }
    },

    fecha: {
      immediate: true,
      async handler (value) {
        await this.init()
      }
    }
  },

  created () {
    this.init()
  },

  methods: {
    /**
     * Inicializa el componente para seleccionar el periodo fiscal actualmente activo
     */
    async init () {
      this.loading = true
      this.periodoActivo = await this.$getPeriodoFiscalActivo()

      const response = await this.$store.dispatch('polizasSAModule/listar', {
        periodo_fiscal_id: this.periodoActivo.id,
        modulo: 'SA',
        mes: this.mes,
        usuario_importa: this.usuario,
        fecha_importa: this.fecha
      })

      this.polizas = response.data.polizas
      this.selectedIds = response.data.polizas.map(f => f.id)
      this.meses = [{ value: null, text: '-- TODOS --' }].concat(response.data.meses.map(f => ({ value: f.mes, text: this.getMonthById(f.mes).value })))
      this.usuarios = [{ value: null, text: '-- TODOS --' }].concat(response.data.usuarios.map(f => ({ value: f.usuario_importa, text: `${f.nombre} ${f.primer_apellido} ${f.segundo_apellido}` })))
      this.fechas = [{ value: null, text: '-- TODAS --' }].concat(response.data.fechas.map(f => ({ value: f.captura, text: `${f.captura}` })))
      this.totalRows = this.polizas.length
      this.loading = false
    },

    onShowMovimientos (id) {
      this.selectedPoliza = this.polizas.find(el => el.id === id)
    },

    onFiltered (filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.selectedIds = filteredItems.map(f => f.id)
      this.currentPage = 1
    },

    async download (id, folio) {
      const getval = await this.$store.dispatch('polizasSAModule/descargar', id)

      await this.$onDownload(getval, `poliza.${folio}.xaml`)

      this.$notify({ message: `Solicitud de descarga de póliza con folio ${folio}.` })
    },

    async cancelar (id, folio) {
      this.id = id
      this.folio = folio
      this.$bvModal.show('ConfirmCancelPolizaDialog')
    },

    async submitted () {
      this.$bvModal.hide('ConfirmCancelPolizaDialog')
      // Realizamos la operación
      const getval = await this.$store.dispatch('polizasSAModule/update', { id: this.id, status: 'Cancelada' })

      if (!getval.error) {
        this.$notify({ message: `Cancelación de póliza con folio ${this.folio}.` })
        this.init()
      }
    },

    async onExport () {
      this.loading = true
      const getval = await this.$store.dispatch('polizasSAModule/exportar', this.selectedIds)
      this.loading = false

      await this.$onDownload(getval, 'polizas.xlsx')

      this.$notify({ message: 'Solicitud de exportación de pólizas' })
    },

    includeFilter (value, filter) {
      if (filter === null) {
        return false
      }
      return value.toLowerCase().includes(filter.toLowerCase())
    },

    leftContent (value, filter) {
      return value.substring(0, value.toLowerCase().indexOf(filter.toLowerCase()))
    },

    filterContent (value, filter) {
      return value.substring(
        value.toLowerCase().indexOf(filter.toLowerCase()),
        value.toLowerCase().indexOf(filter.toLowerCase()) + filter.length)
    },

    rightContent (value, filter) {
      return value.substring(value.toLowerCase().indexOf(filter.toLowerCase()) + filter.length)
    }
  }
}
</script>
